/* Generated by grunt-webfont */



@font-face {
	font-family:"feather-icons";
	src:url("feather-icons.eot?e0a20d6edb74f52c006bb300686580b0");
	src:url("feather-icons.eot?e0a20d6edb74f52c006bb300686580b0#iefix") format("embedded-opentype"),
		url("feather-icons.woff?e0a20d6edb74f52c006bb300686580b0") format("woff"),
		url("feather-icons.ttf?e0a20d6edb74f52c006bb300686580b0") format("truetype");
	font-weight:normal;
	font-style:normal;
}

.feather-icon {
	
		font-family:"feather-icons";
	
	display:inline-block;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


/* Icons */


.icon-activity:before {
	content:"\f101";
}


.icon-airplay:before {
	content:"\f102";
}


.icon-alert-circle:before {
	content:"\f103";
}


.icon-alert-octagon:before {
	content:"\f104";
}


.icon-alert-triangle:before {
	content:"\f105";
}


.icon-align-center:before {
	content:"\f106";
}


.icon-align-justify:before {
	content:"\f107";
}


.icon-align-left:before {
	content:"\f108";
}


.icon-align-right:before {
	content:"\f109";
}


.icon-anchor:before {
	content:"\f10a";
}


.icon-aperture:before {
	content:"\f10b";
}


.icon-archive:before {
	content:"\f10c";
}


.icon-arrow-down-circle:before {
	content:"\f10d";
}


.icon-arrow-down-left:before {
	content:"\f10e";
}


.icon-arrow-down-right:before {
	content:"\f10f";
}


.icon-arrow-down:before {
	content:"\f110";
}


.icon-arrow-left-circle:before {
	content:"\f111";
}


.icon-arrow-left:before {
	content:"\f112";
}


.icon-arrow-right-circle:before {
	content:"\f113";
}


.icon-arrow-right:before {
	content:"\f114";
}


.icon-arrow-up-circle:before {
	content:"\f115";
}


.icon-arrow-up-left:before {
	content:"\f116";
}


.icon-arrow-up-right:before {
	content:"\f117";
}


.icon-arrow-up:before {
	content:"\f118";
}


.icon-at-sign:before {
	content:"\f119";
}


.icon-award:before {
	content:"\f11a";
}


.icon-bar-chart-2:before {
	content:"\f11b";
}


.icon-bar-chart:before {
	content:"\f11c";
}


.icon-battery-charging:before {
	content:"\f11d";
}


.icon-battery:before {
	content:"\f11e";
}


.icon-bell-off:before {
	content:"\f11f";
}


.icon-bell:before {
	content:"\f120";
}


.icon-bluetooth:before {
	content:"\f121";
}


.icon-bold:before {
	content:"\f122";
}


.icon-book-open:before {
	content:"\f123";
}


.icon-book:before {
	content:"\f124";
}


.icon-bookmark:before {
	content:"\f125";
}


.icon-box:before {
	content:"\f126";
}


.icon-briefcase:before {
	content:"\f127";
}


.icon-calendar:before {
	content:"\f128";
}


.icon-camera-off:before {
	content:"\f129";
}


.icon-camera:before {
	content:"\f12a";
}


.icon-cast:before {
	content:"\f12b";
}


.icon-check-circle:before {
	content:"\f12c";
}


.icon-check-square:before {
	content:"\f12d";
}


.icon-check:before {
	content:"\f12e";
}


.icon-chevron-down:before {
	content:"\f12f";
}


.icon-chevron-left:before {
	content:"\f130";
}


.icon-chevron-right:before {
	content:"\f131";
}


.icon-chevron-up:before {
	content:"\f132";
}


.icon-chevrons-down:before {
	content:"\f133";
}


.icon-chevrons-left:before {
	content:"\f134";
}


.icon-chevrons-right:before {
	content:"\f135";
}


.icon-chevrons-up:before {
	content:"\f136";
}


.icon-chrome:before {
	content:"\f137";
}


.icon-circle:before {
	content:"\f138";
}


.icon-clipboard:before {
	content:"\f139";
}


.icon-clock:before {
	content:"\f13a";
}


.icon-cloud-drizzle:before {
	content:"\f13b";
}


.icon-cloud-lightning:before {
	content:"\f13c";
}


.icon-cloud-off:before {
	content:"\f13d";
}


.icon-cloud-rain:before {
	content:"\f13e";
}


.icon-cloud-snow:before {
	content:"\f13f";
}


.icon-cloud:before {
	content:"\f140";
}


.icon-code:before {
	content:"\f141";
}


.icon-codepen:before {
	content:"\f142";
}


.icon-codesandbox:before {
	content:"\f143";
}


.icon-coffee:before {
	content:"\f144";
}


.icon-columns:before {
	content:"\f145";
}


.icon-command:before {
	content:"\f146";
}


.icon-compass:before {
	content:"\f147";
}


.icon-copy:before {
	content:"\f148";
}


.icon-corner-down-left:before {
	content:"\f149";
}


.icon-corner-down-right:before {
	content:"\f14a";
}


.icon-corner-left-down:before {
	content:"\f14b";
}


.icon-corner-left-up:before {
	content:"\f14c";
}


.icon-corner-right-down:before {
	content:"\f14d";
}


.icon-corner-right-up:before {
	content:"\f14e";
}


.icon-corner-up-left:before {
	content:"\f14f";
}


.icon-corner-up-right:before {
	content:"\f150";
}


.icon-cpu:before {
	content:"\f151";
}


.icon-credit-card:before {
	content:"\f152";
}


.icon-crop:before {
	content:"\f153";
}


.icon-crosshair:before {
	content:"\f154";
}


.icon-database:before {
	content:"\f155";
}


.icon-delete:before {
	content:"\f156";
}


.icon-disc:before {
	content:"\f157";
}


.icon-dollar-sign:before {
	content:"\f158";
}


.icon-download-cloud:before {
	content:"\f159";
}


.icon-download:before {
	content:"\f15a";
}


.icon-droplet:before {
	content:"\f15b";
}


.icon-edit-2:before {
	content:"\f15c";
}


.icon-edit-3:before {
	content:"\f15d";
}


.icon-edit:before {
	content:"\f15e";
}


.icon-external-link:before {
	content:"\f15f";
}


.icon-eye-off:before {
	content:"\f160";
}


.icon-eye:before {
	content:"\f161";
}


.icon-facebook:before {
	content:"\f162";
}


.icon-fast-forward:before {
	content:"\f163";
}


.icon-feather:before {
	content:"\f164";
}


.icon-figma:before {
	content:"\f165";
}


.icon-file-minus:before {
	content:"\f166";
}


.icon-file-plus:before {
	content:"\f167";
}


.icon-file-text:before {
	content:"\f168";
}


.icon-file:before {
	content:"\f169";
}


.icon-film:before {
	content:"\f16a";
}


.icon-filter:before {
	content:"\f16b";
}


.icon-flag:before {
	content:"\f16c";
}


.icon-folder-minus:before {
	content:"\f16d";
}


.icon-folder-plus:before {
	content:"\f16e";
}


.icon-folder:before {
	content:"\f16f";
}


.icon-framer:before {
	content:"\f170";
}


.icon-frown:before {
	content:"\f171";
}


.icon-gift:before {
	content:"\f172";
}


.icon-git-branch:before {
	content:"\f173";
}


.icon-git-commit:before {
	content:"\f174";
}


.icon-git-merge:before {
	content:"\f175";
}


.icon-git-pull-request:before {
	content:"\f176";
}


.icon-github:before {
	content:"\f177";
}


.icon-gitlab:before {
	content:"\f178";
}


.icon-globe:before {
	content:"\f179";
}


.icon-grid:before {
	content:"\f17a";
}


.icon-hard-drive:before {
	content:"\f17b";
}


.icon-hash:before {
	content:"\f17c";
}


.icon-headphones:before {
	content:"\f17d";
}


.icon-heart:before {
	content:"\f17e";
}


.icon-help-circle:before {
	content:"\f17f";
}


.icon-hexagon:before {
	content:"\f180";
}


.icon-home:before {
	content:"\f181";
}


.icon-image:before {
	content:"\f182";
}


.icon-inbox:before {
	content:"\f183";
}


.icon-info:before {
	content:"\f184";
}


.icon-instagram:before {
	content:"\f185";
}


.icon-italic:before {
	content:"\f186";
}


.icon-key:before {
	content:"\f187";
}


.icon-layers:before {
	content:"\f188";
}


.icon-layout:before {
	content:"\f189";
}


.icon-life-buoy:before {
	content:"\f18a";
}


.icon-link-2:before {
	content:"\f18b";
}


.icon-link:before {
	content:"\f18c";
}


.icon-linkedin:before {
	content:"\f18d";
}


.icon-list:before {
	content:"\f18e";
}


.icon-loader:before {
	content:"\f18f";
}


.icon-lock:before {
	content:"\f190";
}


.icon-log-in:before {
	content:"\f191";
}


.icon-log-out:before {
	content:"\f192";
}


.icon-mail:before {
	content:"\f193";
}


.icon-map-pin:before {
	content:"\f194";
}


.icon-map:before {
	content:"\f195";
}


.icon-maximize-2:before {
	content:"\f196";
}


.icon-maximize:before {
	content:"\f197";
}


.icon-meh:before {
	content:"\f198";
}


.icon-menu:before {
	content:"\f199";
}


.icon-message-circle:before {
	content:"\f19a";
}


.icon-message-square:before {
	content:"\f19b";
}


.icon-mic-off:before {
	content:"\f19c";
}


.icon-mic:before {
	content:"\f19d";
}


.icon-minimize-2:before {
	content:"\f19e";
}


.icon-minimize:before {
	content:"\f19f";
}


.icon-minus-circle:before {
	content:"\f1a0";
}


.icon-minus-square:before {
	content:"\f1a1";
}


.icon-minus:before {
	content:"\f1a2";
}


.icon-monitor:before {
	content:"\f1a3";
}


.icon-moon:before {
	content:"\f1a4";
}


.icon-more-horizontal:before {
	content:"\f1a5";
}


.icon-more-vertical:before {
	content:"\f1a6";
}


.icon-mouse-pointer:before {
	content:"\f1a7";
}


.icon-move:before {
	content:"\f1a8";
}


.icon-music:before {
	content:"\f1a9";
}


.icon-navigation-2:before {
	content:"\f1aa";
}


.icon-navigation:before {
	content:"\f1ab";
}


.icon-octagon:before {
	content:"\f1ac";
}


.icon-package:before {
	content:"\f1ad";
}


.icon-paperclip:before {
	content:"\f1ae";
}


.icon-pause-circle:before {
	content:"\f1af";
}


.icon-pause:before {
	content:"\f1b0";
}


.icon-pen-tool:before {
	content:"\f1b1";
}


.icon-percent:before {
	content:"\f1b2";
}


.icon-phone-call:before {
	content:"\f1b3";
}


.icon-phone-forwarded:before {
	content:"\f1b4";
}


.icon-phone-incoming:before {
	content:"\f1b5";
}


.icon-phone-missed:before {
	content:"\f1b6";
}


.icon-phone-off:before {
	content:"\f1b7";
}


.icon-phone-outgoing:before {
	content:"\f1b8";
}


.icon-phone:before {
	content:"\f1b9";
}


.icon-pie-chart:before {
	content:"\f1ba";
}


.icon-play-circle:before {
	content:"\f1bb";
}


.icon-play:before {
	content:"\f1bc";
}


.icon-plus-circle:before {
	content:"\f1bd";
}


.icon-plus-square:before {
	content:"\f1be";
}


.icon-plus:before {
	content:"\f1bf";
}


.icon-pocket:before {
	content:"\f1c0";
}


.icon-power:before {
	content:"\f1c1";
}


.icon-printer:before {
	content:"\f1c2";
}


.icon-radio:before {
	content:"\f1c3";
}


.icon-refresh-ccw:before {
	content:"\f1c4";
}


.icon-refresh-cw:before {
	content:"\f1c5";
}


.icon-repeat:before {
	content:"\f1c6";
}


.icon-rewind:before {
	content:"\f1c7";
}


.icon-rotate-ccw:before {
	content:"\f1c8";
}


.icon-rotate-cw:before {
	content:"\f1c9";
}


.icon-rss:before {
	content:"\f1ca";
}


.icon-save:before {
	content:"\f1cb";
}


.icon-scissors:before {
	content:"\f1cc";
}


.icon-search:before {
	content:"\f1cd";
}


.icon-send:before {
	content:"\f1ce";
}


.icon-server:before {
	content:"\f1cf";
}


.icon-settings:before {
	content:"\f1d0";
}


.icon-share-2:before {
	content:"\f1d1";
}


.icon-share:before {
	content:"\f1d2";
}


.icon-shield-off:before {
	content:"\f1d3";
}


.icon-shield:before {
	content:"\f1d4";
}


.icon-shopping-bag:before {
	content:"\f1d5";
}


.icon-shopping-cart:before {
	content:"\f1d6";
}


.icon-shuffle:before {
	content:"\f1d7";
}


.icon-sidebar:before {
	content:"\f1d8";
}


.icon-skip-back:before {
	content:"\f1d9";
}


.icon-skip-forward:before {
	content:"\f1da";
}


.icon-slack:before {
	content:"\f1db";
}


.icon-slash:before {
	content:"\f1dc";
}


.icon-sliders:before {
	content:"\f1dd";
}


.icon-smartphone:before {
	content:"\f1de";
}


.icon-smile:before {
	content:"\f1df";
}


.icon-speaker:before {
	content:"\f1e0";
}


.icon-square:before {
	content:"\f1e1";
}


.icon-star-s:before {
	content:"\f1e2";
}


.icon-star:before {
	content:"\f1e3";
}


.icon-stop-circle:before {
	content:"\f1e4";
}


.icon-sun:before {
	content:"\f1e5";
}


.icon-sunrise:before {
	content:"\f1e6";
}


.icon-sunset:before {
	content:"\f1e7";
}


.icon-tag:before {
	content:"\f1e8";
}


.icon-target:before {
	content:"\f1e9";
}


.icon-terminal:before {
	content:"\f1ea";
}


.icon-thermometer:before {
	content:"\f1eb";
}


.icon-thumbs-down:before {
	content:"\f1ec";
}


.icon-thumbs-up:before {
	content:"\f1ed";
}


.icon-toggle-left:before {
	content:"\f1ee";
}


.icon-toggle-right:before {
	content:"\f1ef";
}


.icon-trash-2:before {
	content:"\f1f0";
}


.icon-trash:before {
	content:"\f1f1";
}


.icon-trello:before {
	content:"\f1f2";
}


.icon-trending-down:before {
	content:"\f1f3";
}


.icon-trending-up:before {
	content:"\f1f4";
}


.icon-triangle:before {
	content:"\f1f5";
}


.icon-truck:before {
	content:"\f1f6";
}


.icon-tv:before {
	content:"\f1f7";
}


.icon-twitter:before {
	content:"\f1f8";
}


.icon-type:before {
	content:"\f1f9";
}


.icon-umbrella:before {
	content:"\f1fa";
}


.icon-underline:before {
	content:"\f1fb";
}


.icon-unlock:before {
	content:"\f1fc";
}


.icon-upload-cloud:before {
	content:"\f1fd";
}


.icon-upload:before {
	content:"\f1fe";
}


.icon-user-check:before {
	content:"\f1ff";
}


.icon-user-minus:before {
	content:"\f200";
}


.icon-user-plus:before {
	content:"\f201";
}


.icon-user-x:before {
	content:"\f202";
}


.icon-user:before {
	content:"\f203";
}


.icon-users:before {
	content:"\f204";
}


.icon-video-off:before {
	content:"\f205";
}


.icon-video:before {
	content:"\f206";
}


.icon-voicemail:before {
	content:"\f207";
}


.icon-volume-1:before {
	content:"\f208";
}


.icon-volume-2:before {
	content:"\f209";
}


.icon-volume-x:before {
	content:"\f20a";
}


.icon-volume:before {
	content:"\f20b";
}


.icon-watch:before {
	content:"\f20c";
}


.icon-wifi-off:before {
	content:"\f20d";
}


.icon-wifi:before {
	content:"\f20e";
}


.icon-wind:before {
	content:"\f20f";
}


.icon-x-circle:before {
	content:"\f210";
}


.icon-x-octagon:before {
	content:"\f211";
}


.icon-x-square:before {
	content:"\f212";
}


.icon-x:before {
	content:"\f213";
}


.icon-youtube:before {
	content:"\f214";
}


.icon-zap-off:before {
	content:"\f215";
}


.icon-zap:before {
	content:"\f216";
}


.icon-zoom-in:before {
	content:"\f217";
}


.icon-zoom-out:before {
	content:"\f218";
}
